.label-float {
    position: relative;
    padding-top: 13px;
}

.label-float input {
    border: 1px solid lightgrey;
    border-radius: 5px;
    outline: none;
    min-width: 26rem;
    padding: 1rem;
    font-size: 1.2rem;
    transition: all .1s linear;
    -webkit-transition: all .1s linear;
    -moz-transition: all .1s linear;
    -webkit-appearance: none;
}

.label-float input:focus {
    border: 2px solid #60278C;
}

.label-float input::placeholder {
    color: transparent;
}

.label-float label {
    pointer-events: none;
    position: absolute;
    top: calc(50% - 8px);
    left: 15px;
    transition: all .1s linear;
    -webkit-transition: all .1s linear;
    -moz-transition: all .1s linear;
    background-color: white;
    padding: 5px;
    box-sizing: border-box;
}

.label-float input:required:invalid+label {
    color: #3951b2;
}

.label-float input:focus:required:invalid {
    border: 2px solid #60278C;
}

.label-float input:required:invalid+label:before {
    content: '';
}

.label-float input:focus+label,
.label-float input:not(:placeholder-shown)+label {
    font-size: 13px;
    top: 0;
    color: #3951b2;
}

.loginButton {
    background-color: rgba(255, 255, 255, 0.4);
    width: 315px;
    padding: 0.5rem;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: black;
    font-family: 'Philosopher', sans-serif; /* Added a fallback font */
    font-size: 1.5rem;
    cursor: pointer;
    z-index: 1;
    border-bottom-left-radius: 22px; /* Bottom left corner */
    border-bottom-right-radius: 22px; /* Bottom right corner */
    transition: transform 0.3s ease, background-color 0.3s ease; /* Smooth transition */
}

.loginButton:hover {
    transform: scale(1.03); /* Slightly enlarge on hover */
    background-color: rgba(255, 255, 255, 0.6); /* Change background color on hover */
}

/* Media Query for Mobile View */
@media (max-width: 600px) {
    .loginButton {
        width: 170px; /* Adjust width for mobile */
        font-size: 1.2rem; /* Optionally decrease font size */
    }
}

/* Media Query for iPad View */
@media (min-width: 601px) and (max-width: 1024px) {
    .loginButton {
        width: 240px; /* Adjust width for iPad */
        font-size: 1.4rem; /* Optionally adjust font size */
    }
}