* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-container {
  display: flex;
}

main {
  padding: 10px;
}

.title {
  font-size: 3rem;
  display: grid;
  place-items: center;
}

/* Sidebar */
.sidebar {
  /* background: #60278C; */
  background: #10395D;
  color: white;
  height: 100vh;
  overflow-y: auto;
  scroll-behavior: smooth;
  overflow-y: auto;
  /* Make content scrollable */
  scroll-behavior: smooth;
}


/* Hide scrollbar for WebKit browsers */
.sidebar::-webkit-scrollbar {
  width: 0;
  /* Set a small width */
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: transparent;
  /* Make thumb (the draggable part) transparent */
}

.top_section {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 0.5rem 0.5rem;
  font-family: 'Philosopher';
  font-weight: 800;
  font-size: 2.5rem;
  color: #fff;
  /* background-color: #1B1B45; */
  background-color: #10395D;

}


.logo {
  font-size: 18px;
  line-height: 0;
}

.bars {
  width: 30px;
}

.hide {
  display: none;
}

.search {
  display: flex;
  align-items: center;
  margin: 10px 0;
  height: 30px;
  padding: 10px;
}

.search input {
  border: none;
  margin-left: 10px;
  border-radius: 5px;
  background: rgb(238, 238, 238);

  color: white;
}

.routes {
  /* margin-top: 15px; */
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.link {
  display: flex;
  color: white;
  gap: 10px;
  padding: 5px 10px;
  text-decoration: none;
  font-family: 'Philosopher';
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.link:hover {
  /* border-right: 4px solid white; */
  /* background: #FFEDCA; */
  background: #9FAFBE;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  color: #1B1B45
}

.active {
  /* border-right: 4px solid #1B1B45; */
  /* background: #FFEDCA; */
  background: #9FAFBE;
  color: #1B1B45
}

.link_text {
  white-space: nowrap;
  font-size: 15px;
}

.menu {
  display: flex;
  color: white;
  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  justify-content: space-between;
}

.menu_item {
  display: flex;
  gap: 10px;
}

.menu_container {
  display: flex;
  flex-direction: column;
}

.menu_container .link {
  padding-left: 20px;
  border-bottom: #1B1B45 1px solid;
}

